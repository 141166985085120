import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import GamepadIcon from '@mui/icons-material/Gamepad';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ImageIcon from '@mui/icons-material/Image';
import CasinoIcon from '@mui/icons-material/Casino';
import { ACCESS_LEVELS, ROLE } from 'src/utils/constants/roles';

const getIcon = (name: string) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  game: <GamepadIcon />,
  edit: getIcon('ic_edit'),
  transactions: getIcon('ic_transactions'),
  category: getIcon('ic_category'),
  provider: <SportsEsportsIcon />,
  summary: <SummarizeIcon />,
  banners: <ImageIcon />,
  bets: <CasinoIcon />,
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'general',
  //   items: [
  //     {
  //       title: 'app',
  //       path: PATH_DASHBOARD.general.app,
  //       icon: ICONS.dashboard,
  //     },
  //     { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
  //     { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
  //     { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
  //     { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
  //   ],
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // {
      //   title: 'user',
      //   path: PATH_DASHBOARD.user.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //     // { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //     // { title: 'list', path: PATH_DASHBOARD.user.list },
      //     // { title: 'create', path: PATH_DASHBOARD.user.newUser },
      //     // { title: 'edit', path: PATH_DASHBOARD.user.editById },
      //     // { title: 'account', path: PATH_DASHBOARD.user.account },
      //   ],
      // },
      // MANAGEMENT : E-COMMERCE
      // {
      //   title: 'e-commerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //     { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
      //   ],
      // },
      // MANAGEMENT : BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.postById },
      //     { title: 'new post', path: PATH_DASHBOARD.blog.newPost },
      //   ],
      // },
    ],
  },

  // // APP
  // // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: (
  //         <Label variant="outlined" color="error">
  //           +32
  //         </Label>
  //       ),
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //     },
  //   ],
  // },
];

const baseNavConfig = {
  subheader: 'management',
  items: {
    bets: { title: 'bets', path: PATH_DASHBOARD.bets.root, icon: ICONS.bets },
    transactions: { title: 'transactions', path: PATH_DASHBOARD.transactions.root, icon: ICONS.transactions },
    users: { title: 'users', path: PATH_DASHBOARD.users.root, icon: ICONS.user },
    summary: { title: 'summary', path: PATH_DASHBOARD.summary.root, icon: ICONS.summary },
    dashboard: { title: 'dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
    marketplace: { title: 'marketplace', path: PATH_DASHBOARD.marketplace.root, icon: ICONS.edit },
    categories: { title: 'categories', path: PATH_DASHBOARD.categories.root, icon: ICONS.category },
    games: { title: 'games', path: PATH_DASHBOARD.games.root, icon: ICONS.game },
    providers: { title: 'providers', path: PATH_DASHBOARD.providers.root, icon: ICONS.provider },
  },
};

export const navConfigByRole = {
  [ACCESS_LEVELS[ROLE.SUPER_ADMIN]]: [
    {
      subheader: baseNavConfig.subheader,
      items: [
        baseNavConfig.items.dashboard,
        baseNavConfig.items.games,
        baseNavConfig.items.bets,
        baseNavConfig.items.providers,
        baseNavConfig.items.transactions,
        baseNavConfig.items.users,
        baseNavConfig.items.summary,
        baseNavConfig.items.marketplace,
        baseNavConfig.items.categories,
      ],
    },
  ],
  [ACCESS_LEVELS[ROLE.ADMIN]]: [
    {
      subheader: baseNavConfig.subheader,
      items: [
        baseNavConfig.items.dashboard,
        baseNavConfig.items.games,
        baseNavConfig.items.bets,
        baseNavConfig.items.providers,
        baseNavConfig.items.transactions,
        baseNavConfig.items.users,
        baseNavConfig.items.summary,
        baseNavConfig.items.categories,
      ],
    },
  ],
  [ACCESS_LEVELS[ROLE.MANAGER]]: [
    {
      subheader: baseNavConfig.subheader,
      items: [baseNavConfig.items.bets, baseNavConfig.items.transactions, baseNavConfig.items.summary],
    },
  ],
  [ACCESS_LEVELS[ROLE.AGENT]]: [
    {
      subheader: baseNavConfig.subheader,
      items: [baseNavConfig.items.bets, baseNavConfig.items.transactions, baseNavConfig.items.summary],
    },
  ],
};

export default navConfig;
